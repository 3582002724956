import { ImageMap } from '../contracts';

import file1, { ReactComponent as JSX1 } from './public/pm/1.svg';
import file2, { ReactComponent as JSX2 } from './public/pm/2.svg';
import file3, { ReactComponent as JSX3 } from './public/pm/3.svg';
import file4, { ReactComponent as JSX4 } from './public/pm/4.svg';
import file5, { ReactComponent as JSX5 } from './public/pm/5.svg';
import file6, { ReactComponent as JSX6 } from './public/pm/6.svg';
import file7, { ReactComponent as JSX7 } from './public/pm/7.svg';
import file8, { ReactComponent as JSX8 } from './public/pm/8.svg';
import file9, { ReactComponent as JSX9 } from './public/pm/9.svg';
import file10, { ReactComponent as JSX10 } from './public/pm/10.svg';
import file11, { ReactComponent as JSX11 } from './public/pm/11.svg';
import file12, { ReactComponent as JSX12 } from './public/pm/12.svg';
import file13, { ReactComponent as JSX13 } from './public/pm/13.svg';
import file14, { ReactComponent as JSX14 } from './public/pm/14.svg';
import file15, { ReactComponent as JSX15 } from './public/pm/15.svg';
import file16, { ReactComponent as JSX16 } from './public/pm/16.svg';
import file17, { ReactComponent as JSX17 } from './public/pm/17.svg';
import file18, { ReactComponent as JSX18 } from './public/pm/18.svg';
import file19, { ReactComponent as JSX19 } from './public/pm/19.svg';
import file20, { ReactComponent as JSX20 } from './public/pm/20.svg';
import file21, { ReactComponent as JSX21 } from './public/pm/21.svg';
import file22, { ReactComponent as JSX22 } from './public/pm/22.svg';
import file23, { ReactComponent as JSX23 } from './public/pm/23.svg';
import file24, { ReactComponent as JSX24 } from './public/pm/24.svg';
import file25, { ReactComponent as JSX25 } from './public/pm/25.svg';
import file26, { ReactComponent as JSX26 } from './public/pm/26.svg';
import file27, { ReactComponent as JSX27 } from './public/pm/27.svg';
import file28, { ReactComponent as JSX28 } from './public/pm/28.svg';
import file29, { ReactComponent as JSX29 } from './public/pm/29.svg';
import file30, { ReactComponent as JSX30 } from './public/pm/30.svg';
import file31, { ReactComponent as JSX31 } from './public/pm/31.svg';
import file32, { ReactComponent as JSX32 } from './public/pm/32.svg';
import file33, { ReactComponent as JSX33 } from './public/pm/33.svg';
import file34, { ReactComponent as JSX34 } from './public/pm/34.svg';
import file35, { ReactComponent as JSX35 } from './public/pm/35.svg';
import file37, { ReactComponent as JSX37 } from './public/pm/37.svg';
import file38, { ReactComponent as JSX38 } from './public/pm/38.svg';
import file39, { ReactComponent as JSX39 } from './public/pm/39.svg';
import file40, { ReactComponent as JSX40 } from './public/pm/40.svg';
import file41, { ReactComponent as JSX41 } from './public/pm/41.svg';
import file42, { ReactComponent as JSX42 } from './public/pm/42.svg';
import file43, { ReactComponent as JSX43 } from './public/pm/43.svg';
import file44, { ReactComponent as JSX44 } from './public/pm/44.svg';
import file45, { ReactComponent as JSX45 } from './public/pm/45.svg';
import file46, { ReactComponent as JSX46 } from './public/pm/46.svg';
import file47, { ReactComponent as JSX47 } from './public/pm/47.svg';
import file48, { ReactComponent as JSX48 } from './public/pm/48.svg';
import file49, { ReactComponent as JSX49 } from './public/pm/49.svg';
import filedefault, {
  ReactComponent as JSXdefault,
} from './public/pm/default.svg';

export const paymentImages: ImageMap = {
  1: { path: file1, component: JSX1 },
  10: { path: file10, component: JSX10 },
  11: { path: file11, component: JSX11 },
  12: { path: file12, component: JSX12 },
  13: { path: file13, component: JSX13 },
  14: { path: file14, component: JSX14 },
  15: { path: file15, component: JSX15 },
  16: { path: file16, component: JSX16 },
  17: { path: file17, component: JSX17 },
  18: { path: file18, component: JSX18 },
  19: { path: file19, component: JSX19 },
  2: { path: file2, component: JSX2 },
  20: { path: file20, component: JSX20 },
  21: { path: file21, component: JSX21 },
  22: { path: file22, component: JSX22 },
  23: { path: file23, component: JSX23 },
  24: { path: file24, component: JSX24 },
  25: { path: file25, component: JSX25 },
  26: { path: file26, component: JSX26 },
  27: { path: file27, component: JSX27 },
  28: { path: file28, component: JSX28 },
  29: { path: file29, component: JSX29 },
  3: { path: file3, component: JSX3 },
  30: { path: file30, component: JSX30 },
  31: { path: file31, component: JSX31 },
  32: { path: file32, component: JSX32 },
  33: { path: file33, component: JSX33 },
  34: { path: file34, component: JSX34 },
  35: { path: file35, component: JSX35 },
  37: { path: file37, component: JSX37 },
  38: { path: file38, component: JSX38 },
  39: { path: file39, component: JSX39 },
  4: { path: file4, component: JSX4 },
  40: { path: file40, component: JSX40 },
  41: { path: file41, component: JSX41 },
  42: { path: file42, component: JSX42 },
  43: { path: file43, component: JSX43 },
  44: { path: file44, component: JSX44 },
  45: { path: file45, component: JSX45 },
  46: { path: file46, component: JSX46 },
  47: { path: file47, component: JSX47 },
  48: { path: file48, component: JSX48 },
  49: { path: file49, component: JSX49 },
  5: { path: file5, component: JSX5 },
  6: { path: file6, component: JSX6 },
  7: { path: file7, component: JSX7 },
  8: { path: file8, component: JSX8 },
  9: { path: file9, component: JSX9 },
  default: { path: filedefault, component: JSXdefault },
};
